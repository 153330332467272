export function download(data : any){
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', `sample_${new Date().getTime()}.csv`);
    a.click()
  }

  export function csvmaker() {
    let data = {
      "vendor_sku": "",
      "merchant_sku": "",
      "product_type": "",
      "name": "",
      "description": "",
      "notes": "",
      "weight": "",
    }
      const csvRows = [];
      const headers = Object.keys(data);
      csvRows.push(headers.join(','));
      const values = Object.values(data).join(',');
      csvRows.push(values)
      return csvRows.join('\n')
  }