import { useNavigate } from "react-router-dom";
import { ADD_PRODUCTS, FILE_TRANSFORM, HOME } from "../vars";

function Header({
  addBtnDisable = false,
  fileTrnsfmBtnDisable = false,
}: {
  addBtnDisable?: boolean;
  fileTrnsfmBtnDisable?: boolean;
}) {
  const navigate = useNavigate();

  const handleTransformFile = () => {
    navigate(FILE_TRANSFORM);
  };

  const handleAddProducts = () => {
    navigate(ADD_PRODUCTS);
  };
  return (
    <>
      <header className="w-screen bg-white absolute px-5 md:flex-row flex-wrap flex-col pb-[30px] md:pb-0 top-0 left-0 flex justify-between ">
        <a href={HOME} className=" flex items-center justify-center">
          <img
            className="w-[100px]"
            src={`${process.env.REACT_APP_S3_BUCKET_LOGO_URL}/CALHOME_logo.png`}
            alt="logo"
          />
        </a>
        <div className="flex gap-2 flex-wrap md:flex-row items-end">
          <button
            className={` font-semibold  py-3 px-3 md:rounded-t-[10px]   w-full md:w-auto ${
              fileTrnsfmBtnDisable
                ? `bg-primary text-white  pointer-events-none`
                : `bg-[#f0f0f0] text-primary`
            }`}
            onClick={handleTransformFile}
          >
            File Transform
          </button>

          <button
            className={` font-semibold  py-3 px-3 md:rounded-t-[10px]   w-full md:w-auto ${
              addBtnDisable
                ? `bg-primary text-white  pointer-events-none`
                : `bg-[#f0f0f0] text-primary`
            }`}
            onClick={handleAddProducts}
          >
            Add New Products
          </button>
        </div>
      </header>
    </>
  );
}

export default Header;
