function DownloadFile() {
   
  return (
    <>
    <svg className="w-[25px] h-[25px]" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="800px" height="800px" viewBox="0 0 16 16">
    <path d="M7 5.222V0h2v5.193l1.107-1.107L11.52 5.5 7.986 9.036 4.45 5.5l1.414-1.414L7 5.222zM16 11v5H0v-5h2v3h12v-3h2z" fillRule="evenodd"/>
    </svg>
    </>
  );
}

export default DownloadFile;