
import { ToastContainer } from "react-toastify";
import "./App.css";
import { Outlet } from "react-router-dom";


function App() {
  return (
    <>
      <Outlet />
      <ToastContainer position="bottom-right" pauseOnHover={false} />
    </>
  );
}

export default App;
