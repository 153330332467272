import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ADD_PRODUCTS, FILE_TRANSFORM } from "../vars";
import App from "../App";
import FileTransform from "../Pages/FileTransform";
import AddProducts from "../Pages/AddProducts";

function ProjectRoutes() {
    const router = createBrowserRouter([
      {
        path: "/",
        element: <App />,
        children: [
          {
            path: "/",
            element: <FileTransform />,
          },
          {
            path: FILE_TRANSFORM,
            element: <FileTransform />,
          },
          {
            path: "*",
            element: <Navigate to="/" replace />,
          },
          {
            path: ADD_PRODUCTS,
            element: <AddProducts />,
          },
        ],
      },
    ]);
  
    return <RouterProvider router={router} />;
  }
  
  export default ProjectRoutes;