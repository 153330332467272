import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { apiClient } from "../utils";
import Header from "../component/Header";
import { csvmaker, download } from "../utils/utils";
import DownloadFile from "../component/DownloadFile";

const defaultFilename = "No file chosen";

function AddProducts() {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [downloadable, setDownloadable] = useState<string[]>([]);
  const [filename, setFilename] = useState<string>(defaultFilename);
  const [resFailed, setResFailed] = useState<any[]>([]);
  const [message, setMessage] = useState<number | undefined>();
  const [flag, setFlag] = useState<any>(false);
  const [addBtnDisable, setAddBtnDisable] = useState<any>(false);

  //   const addOrders = (data: any) => {
  //     toast.loading('Adding orders...');
  //     apiClient
  //       .post('orders/add', data)
  //       .then(() => {
  //         toast.dismiss();
  //         toast.success('Orders added');
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         toast.dismiss();
  //         toast.error('Failed to add orders');
  //       });
  //   };

  useEffect(() => {
    setAddBtnDisable(true);
  }, []);

  const upload = (result: any) => {
    apiClient
      .post(`${process.env.REACT_APP_API_URL}/product/add`, {
        data: result.data,
      })
      .then((link) => {
        toast.dismiss();
        toast.success("File uploaded");
        setSelectedFile(null);
        setFilename(defaultFilename);
        // setDownloadable([...downloadable, link]);
        // addOrders(result.data);
        console.log("data uploaded=", result.data);
        console.log("response=", link.failedData);
        setResFailed(link.failedData);
        setMessage(link?.message?.match(/(\d+)/)?.[0] || "");
        if (link.failedData.length > 0) {
          setFlag(true);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dismiss();
        console.log(result.data);
        toast.error("Failed to upload");
      });
  };

  const clickToUpload = () => {
    (document.getElementById("FileInput") as any).value = "";

    setDownloadable([]);
    if (!selectedFile) {
      return toast.error("Empty file");
    }
    if (!selectedFile.name.includes(".csv")) {
      return toast.error("Wrong file format");
    }
    toast.loading("File uploading...");

    Papa.parse(selectedFile, { complete: upload, header: true });
  };

  //   function handleDownload(){
  //     const sampleFileUrl = window.location.origin + '/sampleFile.csv';
  //     const link = document.createElement('a');
  //     link.href = sampleFileUrl;
  //     link.setAttribute('download', 'sampleFile.csv'); // Change the download filename if needed
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }

  function handleDownload() {
    const data = csvmaker();
    download(data);
  }

  return (
    <>
      <Header addBtnDisable={addBtnDisable} />
      <div className="min-h-screen bg-primary flex flex-col items-center justify-center text-white overflow-y-auto py-[100px]">
        <div className="flex items-center my-5">
          <div className="flex items-center mr-5">
            <span className="tooltip mt-[5px]">
              <button onClick={handleDownload}>
                {" "}
                <DownloadFile />
              </button>
              <span className="tooltip-content">Download Sample File</span>
            </span>
          </div>
          <div className="text-4xl  ">CalHome Add New Products</div>
        </div>
        <form className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center my-6">
            <input
              type="file"
              accept=".csv"
              name="csv"
              id="FileInput"
              onChange={(e) => {
                if (e.target?.files) {
                  setFilename(e.target?.files[0].name);
                  setSelectedFile(e.target?.files[0]);
                }
              }}
              className="w-48 cursor-pointer"
            />
            <div>{filename}</div>
          </div>
          <input
            className="bg-green-500 text-white rounded py-2 w-20 cursor-pointer"
            type="button"
            value="Upload"
            onClick={clickToUpload}
          />
        </form>
        {downloadable.length > 0 && (
          <div className="mt-10 mb-2 text-xl">Click below to download:</div>
        )}
        {downloadable.map((link, index) => (
          <div
            key={index}
            className="text-white cursor-pointer text-md underline hover:text-green-500"
            onClick={() => window.open(link, "_blank")}
          >
            Home Depot CSV
          </div>
        ))}

        {message && (
          <div
            className="p-2 my-2 my-4 text-[16px] text-green-800 border border-l-4 border-green-600   bg-green-200"
            role="alert"
          >
            <span className="font-medium">{message} Products added</span>
          </div>
        )}

        {flag && resFailed.length > 0 && (
          <div className="px-2 w-full">
            <div className="text-3xl my-2 max-w-[1200px] mx-auto  text-left w-full">
              Failed Products({resFailed.length})
            </div>
            <div className="max-w-[1200px] w-full mx-auto my-5 text-left max-h-[500px] overflow-y-auto rounded-[6px] overflow-x-auto border border-[#545454] product-table">
              <table className="w-full">
                <tbody>
                  <tr>
                    <th className="bg-[#5f636b] sticky top-0 p-[10px]">
                      Vendor SKU
                    </th>
                    <th className="bg-[#5f636b] sticky top-0 p-[10px]">
                      Merchant SKU
                    </th>
                    <th className="bg-[#5f636b] sticky top-0 p-[10px]">
                      Product Type
                    </th>
                    <th className="bg-[#5f636b] sticky top-0 p-[10px]">Name</th>
                    <th className="w-[250px] bg-[#5f636b] sticky top-0 p-[10px]">
                      Description
                    </th>
                    <th className="bg-[#5f636b] sticky top-0 p-[10px]">
                      Notes
                    </th>
                    <th className="bg-[#5f636b] sticky top-0 p-[10px]">
                      Weight
                    </th>
                    <th className="bg-[#5f636b] sticky top-0 p-[10px]">
                      Reason
                    </th>
                    {/* <th className='bg-[#5f636b] sticky top-0 p-[10px]'>Status</th> */}
                  </tr>

                  {resFailed.map((el, index) => (
                    <tr key={index}>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.vendor_sku}
                      </td>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.merchant_sku}
                      </td>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.product_type}
                      </td>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.name}
                      </td>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.description}
                      </td>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.notes}
                      </td>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.weight}
                      </td>
                      <td className="border-b border-b-[#5f636b] p-[10px]">
                        {el.reason}
                      </td>
                      {/* <td className='border-b border-b-[#5f636b] p-[10px]'>Status</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddProducts;
